.add-contact-form-wrapper {
  font-family: 'Dosis', sans-serif;
}

.add-contact-form {
  width: 100%;
  height: calc(100vh - var(--footer-height));
  overflow: auto;
  background: var(--card-background);

  transition: .5s;
}

.add-contact-header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-variant: small-caps;
  font-size: 3rem;

  background: var(--card-header-bg);
  border-bottom: 2px solid black;

  padding: 1rem;

  transition: .5s;
}

.contact-form-core {
  padding-bottom: 1rem;
}

.form-wrapper {
  padding: 2rem 1rem;
  max-width: 500px;
  margin: 0 auto 8rem auto;

  transition: color .5s;
}

.contact-form-form {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: auto;
}

.contact-form-section {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

/* //! COLLAPSIBLE SECTIONS */
.collapsible-section-header {
  cursor: pointer;
  display: flex;
}

.form-divider-text {
  display: flex;
  transition: .2s;
}

.collapsible-section-header:hover .form-divider-text {
  text-shadow: 0 0 1px white, 0 0 5px white;
}
.collapsible-section-header:hover .collapsible-section-btn,
.add-contact-form-category-btn:hover  {
  border: 2px solid white;
  text-shadow: none;
}

.collapsible-section-btn {
  padding: .2rem;
  border-radius: 50%;
  border: 2px solid black;
  background: var(--btn-bg);
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center; 
  transition: .5s;
}

.collapsible-section-btn-active {
  transform: rotate(135deg);
}

.collapsible-section-wrapper {
  transition: max-height .75s ease;
  overflow: hidden;
}

.collapsible-section-contact-info {
  max-height: 241px;
}
.collapsible-section-social-links {
  max-height: 641px;
}
.collapsible-section-gaming {
  max-height: 401px;
}

.collapsed {
  max-height: 0px;
}

/* //! ADD CATEGORY BUTTON */


.add-contact-form-category-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}

.add-contact-category-select-wrapper .form-field-header {
  border-radius: 5px 0 0 0;
}

.add-contact-category-select-wrapper .form-field-wrapper {
  border-radius: 5px 0 0 5px;
  border-right: 0;
}


#category.add-contact-input {
  border-radius: 0 0 0 5px;
}



.add-contact-form-category-btn {
  height: 100%;
  width: 5rem;
  font-size: 3rem;
  border-radius: 0 10px 10px 0;
  border: 1px solid black;
  /* border-left: 0; */
  margin-left: 0;
  cursor: pointer;
  background: var(--btn-bg-alt);
  box-shadow: var(--btn-shadow-inner);
  user-select: none;
}
.add-contact-form-category-btn:hover {
  border: 1px solid white;
  background: var(--btn-bg-alt-hover);
}
.add-contact-form-category-btn:active {
  border: 1px solid white;
  box-shadow: none;
  transition: box-shadow 0s;
}


.contact-form-form p {
  display: flex;
  align-items: center;
}

.contact-form-form select {
  border: 0;
  border-radius: 0 0 5px 5px;
  cursor: pointer;
}

.add-contact-input:hover {
  outline: 2px solid green;
  outline: var(--focus-outline);
}

.add-contact-input {
  text-align: left;
}

.form-50 {
  flex-basis: 100%;
  width: 100%;
  display: flex;
}

.flex-left {
  justify-content: center;
}

.flex-right {
  justify-content: center;
}

.form-100 {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  flex-basis: 100%;
}

.add-contact-category-select-wrapper {
  flex-flow: row nowrap;
}

.form-100 label {
  width: 100%;
}

.contact-form-section input,
.contact-form-section select {
  width: 100%;
}

select.add-contact-input:not(#portrait-icon),
.add-contact-input option:not(.portrait-icon-option) {
  text-transform: capitalize;
}


.add-contact-bio {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  min-height: 4rem;
  max-height: 30rem;
  height: 9rem;
  resize: vertical;
}


.form-divider {
  border: 1px solid var(--divider-color);
  background: var(--divider-color);
  border-radius: 5px;
  height: 2px;
  margin-right: 3rem;
  margin-left: 3rem;
  margin-bottom: 2rem;
}

.form-divider-wrapper {
  width: 100%;

  margin-bottom: 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.form-divider-split {
  border: 1px solid var(--divider-color);
  flex: 1;
  height: 2px;
}

.form-divider-split:first-of-type {
  margin-left: 3rem;
}
.form-divider-split:last-of-type {
  margin-right: 3rem;
}

.form-divider-text {
  font-size: 2.5rem;
  font-size: 3rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  font-variant: small-caps;
}

@media all and (min-width: 500px) {
  .form-100 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  .flex-left {
    flex-basis: 48%;
    justify-content: flex-start;
    /* margin-left: 1%; */
  }
  .flex-right {
    flex-basis: 48%;
    justify-content: flex-end;
    /* margin-left: 1%; */
  }
}

/* // FOR SETTINGS PAGE */
.delete-account-btn {
  color: white;
  background: rgb(185, 0, 0);
}
.delete-account-btn:hover {
  color: white;
  background: rgb(230, 0, 0);
}

.form-fieldset {
  border: 2px solid var(--text-color);
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 3rem;
}

.form-legend {
  padding: 0 .5rem;
}

/* // FOR ABOUT PAGE */
.about-text {
  padding: .5rem;
  text-align: center;
  width: 100%;
  line-height: 2.2rem;
}

.about-text-anchor,
.about-text-anchor:link,
.about-text-anchor:visited {
  position: relative;
  color: var(--text-link-color);
  cursor: pointer;
}

.about-text-anchor.about-text-anchor::after,
.about-text-anchor:hover::after {
  content: '';
  position: absolute;
  bottom: 2px;
  left: 0;
  border-bottom: 1px dashed rgba(0,0,0,0);
  border-bottom: 1px dashed var(--text-link-color);
  width: 100%;
  transition: .3s;
}
.about-text-anchor.about-text-anchor:hover::after,
.about-text-anchor:hover::after {
  content: '';
  position: absolute;
  bottom: 2px;
  left: 0;
  border-bottom: 1px dashed var(--text-link-color);
  width: 100%;
}

.about-dev-wrapper {
  display: flex;
  flex-flow: column nowrap;
}

.about-list-item {
  margin-bottom: 1rem;
}
.about-list-item:last-child {
  margin-bottom: unset;
}

.about-list-item-steps {
  position: relative;
  text-align: left;
  width: 100%;
  text-indent: 1.3rem;
  margin-bottom: 1rem;
}

.about-list-item-steps:before {
  content: '';

  position: absolute;
  left: 3px;
  top: 10px;

  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: var(--text-color);
}

.about-list-ordered {
  margin-bottom: 1rem;
}


.about-list-item-nums {
  text-align: left;
  list-style: inside decimal;
  margin-bottom: 1rem;
  padding: .2rem;
}
.about-list-item-nums:nth-child(2n){
  background: rgba(0,0,0,.1);
  border-radius: 5px;
}

.about-wrapper .form-field-wrapper,
.stats-wrapper .form-field-wrapper {
  cursor: unset;
}

.about-social-icons {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.about-social-icon {
  font-size: 3rem;
  margin: .7rem;
  cursor: pointer;
  transition: .3s;
}

.about-social-icon-anchor:link,
.about-social-icon-anchor:visited {
  color: var(--text-color);
}


.about-social-icon:hover {
  color: var(--btn-bg);
}

.theme-purity .about-social-icon:hover {
  color: var(--btn-bg);
  text-shadow: var(--social-icon-outline);
}

.spark {
  color: var(--btn-bg);
  color: var(--spark-color);
  text-shadow: var(--text-outline-darker);
  font-weight: 600;
  margin-left: .2rem;
  margin-right: .2rem;
}

.final-button-li {
  margin-top: 2rem;
}

/* // FOR CHANGELOG */
.changelog-list-item {
  text-align: left;
}

.changelog-icon {
  width: 2.4rem;
  text-align: center;
}

/* // FOR STATS */
.admin-data-top {
  margin-top: 1rem;
}