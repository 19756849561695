.header-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  height: var(--header-height);
  width: 100%;

  background: var(--header-bg);

  border-bottom: 2px solid black;
  z-index: 7;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  max-width: var(--app-width);
  padding: 1rem;
}

.logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Dosis', sans-serif;
  font-weight: bold;
}

.logo-link {
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  color: var(--primary-color-dark);
  color: var(--text-logo-color);
  text-shadow: var(--text-outline-themed);
  transition: 0.2s;
  height: 3rem;
}

.logo-link:hover {
  color: var(--primary-color);
  color: var(--primary-color-dark);
  color: var(--secondary-color);
  text-shadow: 0 0 10px var(--secondary-color);
  text-shadow: 0 0 5px var(--primary-color-dark);
  text-shadow: var(--text-shadow-dark);
  text-shadow: var(--text-outline-dark);
  color: white;
}

.logo-text {
  display: block;
  /* color: var(--text-color); */
  /* align-items: flex-start; */
  /* padding-bottom: .6rem; */
}

.fa-solid + .logo-text {
  padding-bottom: 0.6rem;
}

.fa-users-viewfinder {
  font-size: 2.5rem;
}

.header-menu {
  display: flex;
  justify-content: flex-end;
}

.header-menu > li {
  margin-right: 1rem;
}
.header-menu > li:last-child {
  margin-right: 0;
}

.demo-btns-wrapper {
  display: flex;
  align-items: center;
  font-size: 2rem;
}
