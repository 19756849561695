.landing-form {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}


.landing-footer-wrapper {
  width: 100%;
  background: var(--card-background);
  padding: 1rem;
  margin-top: 1rem;
  border-top: 2px solid black;
}

.landing-footer-menu {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-size: 1.7rem;
}

.landing-footer-text {
  font-family: 'Dosis', sans-serif;
}

.landing-footer-link {
  color: var(--text-color) !important;
}

.landing-footer-link::after {
  border-bottom: 1px dashed var(--text-color) !important;
}

.landing-footer-social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: .8rem;
}

.landing-footer-social-icons .about-social-icon {
  font-size: 1.8rem;
  margin: 0 .4rem;
}

@media all and (min-width: 340px) {
  .landing-footer-menu {
    flex-flow: row nowrap;
  }
  .landing-footer-social-icons {
    margin: 0 .4rem;
  }
}