/* //// Clear default button styles. Code from https://gist.github.com/MoOx/9137295 */
button {
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  width: auto;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  /* appearance: none; */
}

.btn-wrapper {
  width: 100%;
  margin-bottom: 1rem;
}
.btn-wrapper:last-child {
  margin-bottom: 0;
}

/* TODO OPTIMIZING */
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  background: var(--btn-bg);
  color: var(--btn-text-color);

  border: var(--btn-border);
  border-radius: 100px;
  box-shadow: var(--btn-shadow-inner);

  padding: 1rem;

  cursor: pointer;

  transition: 0.7s;
}

.btn:hover {
  background: var(--btn-bg-hover);
  border: var(--btn-border-hover);
  color: var(--btn-text-color-hover);

  transition: 0.3s;
}

.btn:active {
  border: var(--btn-border-hover);
  color: var(--btn-text-color-hover);

  box-shadow: var(--btn-shadow-inner-active);

  transition: 0s;
}

/* ! --- PROMPT BUTTONS --- */
.prompt-btn {
  color: black !important;
}
.prompt-btn-no-transition {
  transition: 0s !important;
}

.btn-prompt-confirm,
.btn-prompt-confirm:hover,
.btn-prompt-confirm:active {
  background: var(--btn-prompt-confirm-bg);
}
.btn-prompt-delete,
.btn-prompt-delete:hover,
.btn-prompt-delete:active {
  background: var(--btn-prompt-delete-bg);
}
.btn-prompt-cancel,
.btn-prompt-cancel:hover,
.btn-prompt-cancel:active {
  background: var(--btn-prompt-cancel-bg);
}

.prompt-btn.btn-disabled {
  background: grey !important;
  box-shadow: none !important;
  border: 2px solid black !important;
  cursor: unset !important;
}

.btn-glow {
  box-shadow: var(--btn-shadow-inner), 0 0 10px white;
  animation: glow 0.8s linear infinite;
}
.animations-off .btn-glow {
  box-shadow: var(--btn-shadow-inner), 0 0 7px 7px white;
  animation: none;
}

@keyframes glow {
  0% {
    background-position: 0% 50%;
    box-shadow: var(--btn-shadow-inner), 0 0 1px white;
  }
  50% {
    background-position: 100% 50%;
    box-shadow: var(--btn-shadow-inner), 0 0 5px 8px white;
  }
  100% {
    background-position: 0% 50%;
    box-shadow: var(--btn-shadow-inner), 0 0 1px white;
  }
}

/* ! --- CHECK IN BUTTON --- */

.btn-checkin {
  display: flex;
  justify-content: center;
  position: relative;
}

.btn-checkin:has(.btn-checkin-text:hover) {
  background: var(--btn-bg-hover);
  border: var(--btn-border-hover);
  color: var(--btn-text-color-hover);
  transition: 0.3s;
}
.btn-checkin:has(.btn-snooze-i-wrapper:hover) {
  background: var(--btn-bg);
  border: var(--btn-border);
  color: var(--btn-text-color);
  transition: 0.3s;
}

.btn-snooze-i-wrapper {
  position: absolute !important;
  right: 0;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  /* height: 49px; */
}

.btn-snooze-i {
  padding-left: 0.5rem;
  color: var(--snooze-icon-color);

  border-left: 2px dotted var(--snooze-icon-color);

  transition: 0.3s;
}

.btn-snooze-i-wrapper:hover .btn-snooze-i {
  color: var(--snooze-icon-color-hover);

  border-left: 2px solid var(--snooze-icon-color-hover);

  text-shadow: var(--text-outline-dark);
}

/* ! --- DONATE BUTTON --- */
.btn-donate-wrapper.btn-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.btn-donate {
  border-radius: 100px;
  background: rgb(255, 143, 205);
  color: black;
  width: 200px;
}

.btn-donate:hover {
  border-radius: 100px;
  background: rgb(255, 178, 220);
  color: black;
}

.btn-donate-icon {
  margin-right: 0.5rem;
  color: rgb(255, 45, 45);
  text-shadow: var(--text-outline-dark);
  transition: 0.3s;
}

/* ! --- DEMO ACCOUNT - CREATE FREE ACOUNT BUTTON --- */

.btn-demo-promo {
  padding: 0.7rem 1rem !important;
  animation: glow 2s linear infinite;
}

@media all and (max-width: 355px) {
  .btn-demo-promo {
    font-size: 1.5rem;
  }
}
