.card-wrapper {
  background: var(--card-background);
  border: 2px solid var(--card-border-color);
  border-radius: var(--card-border-radius);

  box-shadow: var(--card-box-shadow);

  margin-bottom: 1rem;
}

.card-wrapper:last-of-type {
  margin: 0;
}

.card-wrapper-flag {
  margin-top: -5px;
  margin-bottom: 0;

  padding-top: 5px;

  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  box-shadow: -2px 0 4px black, 2px 0 4px black, 0 2px 4px black;

  position: sticky;
  z-index: 6;
}

.card-wrapper-tabbed {
  border-top-left-radius: 0;
  margin-bottom: 2rem;
}