/* ! ----------- CSS RESET ------------ */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
  text-decoration: none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

* {
  box-sizing: border-box;
}

i {
  font-style: italic;
}

strong {
  font-weight: bold;
}

/* TODO HERE WE GOOOO */
html, body, #root, .global-wrapper, .app-wrapper {
  height: 100% !important;
}

.app.logged-in {
  height: calc(100vh - var(--footer-height)) !important;
}
.app {
  height: 100%;
}

/* .material-icons-round {
  font-size: inherit;
} */

/* ! ----------- FONTS ------------  */
/* // FONT IMPORTS */
/* Share Tech Mono */
/* @import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap'); */
/* source Sans Pro */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');
/* Acme */
/* @import url('https://fonts.googleapis.com/css2?family=Acme&display=swap'); */
/* Dosis */
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@500;800&display=swap');
/* Raleway */
/* @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap'); */
/* Nunito */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

:root {
  font-family: 'Source Sans Pro', sans-serif;
  /* font-family: 'Nunito', sans-serif; */
  font-size: 10px;
  /* font-variant: small-caps; */
  scroll-behavior: smooth;
}

:root .animations-off * {
  transition: 0s !important;
}

.material-icons-round,
.material-icons,
.action-icon {
  font-variant: normal;
}


body {
  font-size: 2rem;
  background: var(--app-bg);
  /* overflow: hidden; */
  /* color: var(--text-color); */
}


/* ::placeholder {
  color: var(--placeholder-text-color);
} */

.text-link:link,
.text-link:visited {
  color: var(--text-link-color);
  position: relative;
  transition: .3s;
}
.text-link::after {
  position: absolute;
  content: '';
  width: 100%;
  bottom: 0px;
  left: 0;
  border-bottom: 1px dashed rgba(0,0,0,0);
  transition: .3s;
}

.text-link:hover::after {
  position: absolute;
  content: '';
  width: 100%;
  bottom: 0px;
  left: 0;
  border-bottom: 1px dashed var(--text-link-color);
  transition: .3s;
}

:root {
  /* // --- GLOBAL --- */
  --app-width: 800px;
  /* // --- HEADER --- */
  --header-height: 5rem;
  /* // --- FOOTER --- */
  --footer-height: 4rem;
  --footer-btn-size: 66px;
  /* // --- BUTTONS --- */
  --btn-border-radius-big: 10px;
  
  --btn-shadow-inner: inset 2px 2px 5px rgba(255, 255, 255, 0.5), inset -2px -2px 5px rgba(0, 0, 0, 0.5);
  --btn-shadow-inner-small: inset 2px 2px 2px rgba(255, 255, 255, 0.5), inset -2px -2px 2px rgba(0, 0, 0, 0.5);

  --btn-shadow-inner-hover: inset 2px 2px 2px rgba(255, 255, 255, 0.7), inset -2px -2px 2px rgba(0, 0, 0, 0.7);
  --btn-shadow-inner-active: none;

  /* // --- CONTACT CARDS --- */
  --card-border-height: 2px;
  --card-border-radius: 10px; 
  --card-border: var(--card-border-height) solid black; 

  
  /* // --- TEXT SHADOWS --- */
  --text-shadow-dark: -1px 1px 1px black;
  --text-shadow-light: -1px 1px 1px grey;
  --text-shadow-white: 0px 0px 3px white;

  --text-outline-dark:
    0 0 1px black,
    0 0 1px black,
    0 0 1px black,
    0 0 1px black,
    0 0 2px black,
    0 0 2px black,
    0 0 2px black;
  --text-outline-darker:
    /* 0 0 2px black, */
    0 0 1px black,
    0 0 1px black,
    0 0 1px black,
    0 0 1px black,
    0 0 2px black,
    0 0 2px black,
    0 0 2px black,
    1px 1px 1px black,
    1px -1px 1px black,
    -1px 1px 1px black,
    -1px -1px 1px black;
  --text-outline-med:
    0 0 1px black,
    0 0 1px black,
    0 0 1px black,
    0 0 1px black;


}




/* ! --- SCROLLBARS --- */
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 20px;
  /* border: 1px solid var(--scrollbar-thumb-border); */
}



/* ! ------------- UTILITY CLASSES ---------- */
.center-text {
  text-align: center;
}
.text-shadow-dark {
  text-shadow: var(--text-shadow-dark);
}
.text-shadow-light {
  text-shadow: var(--text-shadow-light);
}
/* .text-outline-dark {
  text-shadow: var(--text-outline-med);
} */





