.contact-detail-wrapper {
  flex: 1;
  border: 1px solid black;
  border-radius: 5px;

  display: flex;
  flex-flow: column nowrap;
}

.contact-detail-wrapper:first-of-type {
  margin-bottom: .5rem;
}

.contact-detail {
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
}

.contact-detail-key {
  width: 3rem;
  min-height: 3rem;

  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--btn-bg-alt);
}

.contact-detail:first-of-type .contact-detail-key {
  border-radius: 4px 0 0 0;
}
.contact-detail:first-of-type .contact-detail-value {
  border-radius: 0 4px 0 0;
}

.contact-detail:last-of-type .contact-detail-key {
  border-radius: 0 0 0 4px;
}
.contact-detail:last-of-type .contact-detail-value {
  border-radius: 0 0 4px 0;
}

.contact-detail:only-of-type .contact-detail-key {
  border-radius: 4px 0 0 4px;
}
.contact-detail:only-of-type .contact-detail-value {
  border-radius: 0 4px 4px 0;
} 

.contact-detail-value {
  flex: 1;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;

  min-width: 9rem;
  padding: .5rem;

  background: var(--app-bg-inner);
}

/* //// CONTACT DETAILS - Maybe extract as component?*/
.contact-details-wrapper {
  display: flex;
  flex-flow: column nowrap;
}

.contact-details-label {
  background: lightgreen;
  text-align: center;
  padding: .5rem;
}

.contact-detail {
  display: flex;
}

.contact-detail:first-of-type .contact-detail-value:not(.contact-notes) {
  border-radius: 0 4px 0 0;
}

.contact-detail:last-of-type .contact-detail-value:not(.contact-notes) {
  border-radius: 0 0 4px 0;
}

.contact-detail:only-of-type .contact-detail-property:not(.notes-btn) {
  border-radius: 4px 0 0 4px;
}
.contact-detail:only-of-type .contact-detail-value:not(.contact-notes) {
  border-radius: 0 4px 4px 0;
}