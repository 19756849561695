/* ! --- LOCAL VARS --- */
:root {
  --footer-animation-duration: 1s;
}

/* ! --- FOOTER WRAPPER --- */
.footernav-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;

  height: var(--footer-height);

  background: var(--header-bg);

  border-top: 2px solid black;

  display: flex;
  flex-flow: column nowrap;

  z-index: 7;

  /* transition: var(--footer-animation-duration); */
  transition: all var(--footer-animation-duration), color .2s;
}

/* ! --- FOOTER MENU --- */
.footer-menu {
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translate(-50%);

  width: 100%;
  max-width: var(--app-width);
  height: 100%;
  display: flex;

  opacity: 1;
  transition: .5s linear .5s;
}

.footer-menu-left,
.footer-menu-right {
  flex: 1;
  position: relative;

  padding: .5rem;
  display: flex;
  justify-content: space-evenly;

}

.footer-menu-left {
  margin-right: calc(var(--footer-btn-size) / 2)
}
.footer-menu-right {
  margin-left: calc(var(--footer-btn-size) / 2)
}

.fa-circle-info {
  font-size: 2.3rem;
}

.hidden {
  opacity: 0;
  transition: .5s;
  pointer-events: none;
}

/* ! --- BIG CENTERED PLUS BUTTON --- */
.add-btn-wrapper {
  position: absolute;
  top: calc(0px - calc(var(--footer-btn-size) / 2) - 2px);
  left: 50%;
  transform: translate(-50%);

  height: var(--footer-btn-size);
  width: var(--footer-btn-size);

  display: flex;
  justify-content: center;
  align-items: center;

  transition: var(--footer-animation-duration);
}


.add-btn {
  position: relative;

  height: 100%;
  width: 100%;

  border-radius: 50%;
  border: 3px solid black;

  background: var(--btn-bg);
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  user-select: none;
  transition: .5s;
}

.add-btn-icon {
  font-size: 5rem !important;
}

.add-btn:hover {
  border: 3px solid var(--btn-border-hover-color);
  background: var(--btn-bg-hover);
}


/* ! --- ANIMATIONS --- */
/* // --- FOOTER SLIDES --- */
.footer-open {
  height: calc(100% + var(--card-border-height));
}

.overflow-auto {
  overflow: auto;
}

/* // --- BUTTON MOVEMENT --- */
.scooted-right {
  left: calc(100% - var(--footer-btn-size) + .5rem);
  top: -7px;

  transform: rotate(135deg) scale(.65);
}


/* Alter button's final position on wider screens to stick to the right of the 500px wide form*/
@media all and (min-width: 500px) {
  .scooted-right {
    left: 50%;
    transform: translateX(calc(-50% + 250px - (var(--footer-btn-size) / 2))) rotate(135deg) scale(.65);
  }
}

/* // --- FOOTER FORM FADE --- */
.footer-form-wrapper {
  opacity: 1;
  transition: all var(--footer-animation-duration), color .2s;
}

.fadeout {
  opacity: 0;
  pointer-events: none;
  transition: all var(--footer-animation-duration), color .2s;
}