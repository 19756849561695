.spinner-wrapper-bg {
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,.5);
}

.spinner-wrapper {
  position: absolute;
  z-index: 25;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spin {
  animation: spin 1s linear infinite;
}

.spinner-wrapper .spin {
  font-size: 15rem;
  color: var(--primary-color);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}