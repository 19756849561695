/* // WRAPPER */
.login-wrapper {
  padding: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  min-height: 330px;
  min-width: 250px;
  flex: 1;
}

.login-window {
  position: relative;
  width: 100%;
}

.login-window-header {
  display: flex;
  justify-content: center;


  margin-bottom: 2rem;
}

.login-window-header h1 {
  font-size: 3rem;
}

/* // CORNER EXIT BUTTON */
.btn-exit {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 2;

  color: black;
  font-size: 1.5rem;

  background: orange;

  width: 25px;
  height: 25px;
  
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  transition: .7s;
}

.btn-exit:hover {
  transform: scale(1.1);

  transition: .3s;
}

/* // FORM */
.auth-form {
  width: 200px;
  margin: auto;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.auth-form-element {
  width: 100%;
  margin-bottom: 1rem;
}

.auth-form-element input {
  width: 100%;
}

.auth-form-element span {
  display: block;
}

.auth-footer {
  margin-top: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

.forgot {
  font-size: 1.5rem;
}

/* ! --- LINKS --- */
.auth-footer a:link {
  font-weight: bold;
  position: relative;

  color: var(--text-link-color);
}
.auth-footer a:visited {
  color: var(--text-link-color);
}
.auth-footer a:hover {
  color: var(--text-link-color);
}

.auth-footer a:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  border-bottom: 1px dashed rgba(0,0,0,0);
  width: 100%;
  transition: .3s;
}
.auth-footer a:hover:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  border-bottom: 1px dashed var(--text-link-color);
  width: 100%;
}