/* ! --- On parent Help element. Invisibly preloads help images. */
.preloader {
  display: none;
}


.slide-wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.slide-image-wrapper {
  height: 100%;
  border-radius: 5px;
}

.slide-image {
  width: 100%;
  max-height: 100%;
  max-height: 50vh;
}

.protect {
  pointer-events: none;
}

.no-frame {
  box-shadow: none;
}

.slide-text-wrapper {
  display: flex;
  flex-flow: column nowrap;
}

.slide-text-header {
  display: flex;
  justify-content: center;
  font-size: 2.5rem;
}
.slide-text-description {
  display: flex;
  justify-content: center;
  max-width: 500px;
  min-height: 6rem;
}

.slide-nav-dots {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 2rem;
  margin: .2rem 0;
}

.slide-nav-dot-wrapper {
  height: 2.5rem;
  width: 2.5rem;
  margin-top: .3rem;
  padding: .5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.slide-nav-dot {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  border: 2px solid var(--text-color);
  transition: .3s;
}

.slide-nav-dot-wrapper:hover > .slide-nav-dot {
  background: var(--btn-prompt-confirm-bg);
}

.slide-nav-dot-seen {
  background: var(--text-color);
}

.slide-nav-dot-current {
  background: var(--btn-prompt-confirm-bg);
  /* height: 1.5rem; */
  /* width: 1.5rem; */
  transform: scale(1.5);
}

.slide-text-wrapper {
  margin-bottom: 1rem;
}

.slide-text-description {
  text-align: center;
}

@media all and (max-width: 400px) {
  .slide-nav-dot-wrapper {
    height: 2rem;
    width: 2rem;
  }
  .slide-nav-dot-current {
    background: var(--btn-prompt-confirm-bg);
    height: 1rem;
    /* width: rem; */
  }
}