.form-field-wrapper {
  flex: 1;

  display: flex;
  flex-flow: column nowrap;

  border: 1px solid black;
  border-radius: 5px;

  margin-bottom: 1rem;
  cursor: pointer;
}

.form-field-header,
.form-field-input-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  font-size: 2rem;
}

.form-field-header {
  align-items: center;
  background: var(--app-bg);
  background: var(--btn-bg-alt);
  border-radius: 5px 5px 0 0;
  padding: .5rem;
  height: 3rem;
}

.form-field-header span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-field-icon {
  padding-right: .5rem;
  padding-left: .5rem;
  width: 3rem;
}

.form-field-input-wrapper {
  flex: 1;
  background: var(--app-bg-inner);
  border-radius: 0 0 5px 5px;
}

.form-field-input-wrapper input,
.form-field-input-wrapper textarea,
.form-field-input-wrapper select{
  background: white;
}

.form-field-input-wrapper > * {
  flex: 1;
  background: var(--app-bg-inner);
  /* background: white; */
  border: 0;
  /* outline: 0; */
  border-radius: 0 0 5px 5px;
  padding: 1rem;
  min-height: 3.8rem;
}



.form-field-input-wrapper > input[type='date'],
.form-field-input-wrapper > select {
  cursor: pointer;
}

.form-field-input-wrapper > input {
  cursor: revert;
}

/* //! Horizontal Mode Adjustments */
.form-field-wrapper.horizontal {
  flex-flow: row nowrap;
}

.form-field-wrapper.horizontal .form-field-header {
  border-radius: 5px 0 0 5px;
  height: unset;
}
.form-field-wrapper.horizontal .form-field-input-wrapper {
  border-radius: 0 5px 5px 0;
}
.form-field-wrapper.horizontal input {
  border-radius: 0 5px 5px 0;
}
.form-field-wrapper.horizontal textarea {
  border-radius: 0 5px 5px 0;
}

/* //! --- SETTINGS SPECIFIC STYLES --- */
.settings-wrapper select,
.settings-wrapper option {
  text-transform: none;
}