.social-icon {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid rgba(0,0,0,0);
}

.social-icon a {
  color: unset;
}

.social-icon i {
  font-size: 3rem;
  transition: .7s;
}


.social-icon:hover i {
  color: orange;
  color: var(--primary-color-dark);
  color: var(--btn-bg);
  text-shadow: var(--social-icon-outline);
  cursor: pointer;

  transition: .3s;
}

.social-icon-popup {
  display: flex;
  flex-flow: column nowrap;
  opacity: 0;
  white-space: nowrap;
  z-index: 2;
  max-width: calc(100vw - 30px);

  position: absolute;
  top: -66px;
  left: 50%;
  transform: translateX(-50%);

  padding: .5rem;
  min-width: 200px;

  background: var(--app-bg-inner);
  border: 1px solid black;
  border-radius: 5px;

  pointer-events: none;

  overflow: hidden;
}

.social-icon:hover .social-icon-popup {
  pointer-events: unset;
  opacity: 1;
}

.social-icon-text {
  padding-bottom: .5rem;
  text-align: center;
}

.social-icon-btns {
  display: flex;
}

.social-icon-btn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  height: 3rem;


  box-shadow: var(--btn-shadow-inner-small);

  border: 1px solid black;
  background: var(--btn-bg-alt);

  transition: .5s;
}

.social-icon-btn-left {
  border-radius: 5px 0 0 5px;
}

.social-icon-btn-right {
  position: relative;
  left: -1px;
  border-radius: 0 5px 5px 0;
}

.social-icon-btn-full {
  border-radius: 5px;
}

.social-icon-btn:hover {
  transition: .3s;
  background:rgb(197, 255, 197);
  background:var(--btn-bg-hover);
  z-index: 2;
}

.social-icon-btn:active {
  box-shadow: none;

  transition: 0s;
}

.social-icon-icon {
  z-index: 1;
  border: 1px solid rgba(0,0,0,0);
  border-radius: 0 0 5px 5px;
  padding: .5rem;
}

.social-icon:hover .social-icon-icon {
  z-index: 3;
  background: linear-gradient(to top, white, var(--app-bg-inner));
  border-right: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  border-top: 1px solid var(--app-bg-inner);
  border-radius: 0 0 5px 5px;
}