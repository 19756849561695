
.social-icons-wrapper {
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}


.social-icon-divider {
  width: 10px;
  height: 10px;

  background: black;

  border-radius: 20px;

  margin: 0 1rem;
}

