/* // APP */
.app-wrapper {
  width: 100%;
  min-height: 100vh;
  background: var(--app-bg);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.app {
  width: 100%;
  overflow: auto;
}

.app-body {
  width: 100%;
  max-width: var(--app-width);
  margin: auto;
  padding-left: .5rem;
  padding-right: .5rem;
  padding-bottom: .5rem;

  /* // This bit makes the sticky HUD work... somehow. */
  overflow-y: clip;
}

.logged-in {
  height: calc(100vh - var(--footer-height));
  margin-bottom: var(--footer-height);
}

.logged-in .app-body {
  margin-bottom: var(--footer-height);
  -webkit-margin-bottom: calc(var(--footer-height) * 2)
}