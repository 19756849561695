/* ! ------------- THEMES --------------- */


/* ! ------------- DEFAULT - OASIS --------------- */
:root .theme-oasis {
  /* //! --- PRIMARY AND SECONDARY COLORS --- */
  /* // PRIMARY */
  --primary-color: hsl(46, 100%, 72%);
  --primary-color-h: 46;
  --primary-color-s: 100%;
  --primary-color-l: 72%;
  --primary-color: hsl(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l));

  --primary-color-light:
    hsl(
      var(--primary-color-h),
      var(--primary-color-s),
      calc(var(--primary-color-l) + 10%)
    );
  --primary-color-dark:
    hsl(
      calc(var(--primary-color-h) - 3),
      var(--primary-color-s),
      calc(var(--primary-color-l) - 22%)
    );

  /* // SECONDARY */
  --secondary-color: hsl(120, 73%, 75%);
  --secondary-color-h: 120;
  --secondary-color-s: 73%;
  --secondary-color-l: 75%;
  --secondary-color: hsl(var(--secondary-color-h), var(--secondary-color-s), var(--secondary-color-l));

  --secondary-color-hover:
    hsl(
      var(--secondary-color-h),
      var(--secondary-color-s),
      calc(var(--secondary-color-l) + 10%)
    );
    
  --secondary-color-light:
    hsl(
      var(--secondary-color-h),
      var(--secondary-color-s),
      calc(var(--secondary-color-l) + 20%)
    );
  --secondary-color-dark:
    hsl(
      calc(var(--secondary-color-h) - 3),
      var(--secondary-color-s),
      calc(var(--secondary-color-l) - 22%)
    );

  /* //! --- GLOBAL --- */
  --window-bg: var(--secondary-color);
  --app-bg: var(--secondary-color);
  --header-bg: var(--primary-color-light);

  --app-bg-inner: var(--secondary-color-light);

  /* //! --- TEXT --- */
  --text-color: rgb(0, 0, 0);
  color: var(--text-color);
  --text-placeholder-color: rgb(122, 122, 122);
  --text-link-color: rgb(0, 119, 73);
  --spark-color: var(--btn-bg);

  /* //! --- DIVIDERS --- */
  --divider-color: black;


  /* //! --- CARDS --- */
  --card-background: var(--primary-color);
  --card-header-bg: var(--primary-color-light);
  --card-border-color: black;
  --card-box-shadow: -3px 3px 5px black;

  /* ! --- UI ELEMENTS --- */
  /* // --- SCROLLBAR COLORS --- */
  --scrollbar-thumb: var(--primary-color-dark);
  --scrollbar-track: var(--primary-color);

  /* // --- BUTTONS --- */
  --btn-bg: var(--primary-color-dark);
  /* --btn-bg-hover: hsla(calc(var(--primary-color-h) - 3),
  var(--primary-color-s),
  calc(var(--primary-color-l) - 22%), .7); */
  --btn-bg-hover: hsl(43, 100%, 56%);

  --btn-shadow-hover-glow: 0 0 5px white;

  --btn-bg-alt: var(--secondary-color);
  --btn-bg-alt-hover: var(--secondary-color-hover);

  --btn-bg-disabled: rgb(197, 197, 197);

  --btn-text-color: black;
  --btn-text-color-hover: black;

  --btn-border: 2px solid black;
  --btn-border-hover-color: white;
  --btn-border-hover: 2px solid var(--btn-border-hover-color);

  --action-icon-color-hover: var(--primary-color-dark);

  /* // --- POPUP PROMPT BUTTONS --- */
  --btn-prompt-confirm-bg: lightgreen;
  --btn-prompt-delete-bg: lightcoral;
  --btn-prompt-cancel-bg: rgb(255, 190, 190);

  /* // --- FOCUS OUTLINES */
  --focus-outline: 2px solid green;

  /* // --- NOTE HOVER COLOR */
  --note-hover-color: hsla(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l), 50%);

  /* // --- SNOOZE ICON COLOR --- */
  --snooze-icon-color: black;
  --snooze-icon-color-hover: white;
}

/* ! ------------- END DEFAULT - OASIS --------------- */

/* ! ------------- ICECORE --------------- */
:root .theme-icecore {
  /* //! --- PRIMARY AND SECONDARY COLORS --- */
  /* // PRIMARY */
  --primary-color: hsl(187, 100%, 72%);
  --primary-color-h: 187;
  --primary-color-s: 100%;
  --primary-color-l: 72%;
  --primary-color: hsl(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l));

  --primary-color-light:
    hsl(
      var(--primary-color-h),
      var(--primary-color-s),
      calc(var(--primary-color-l) + 10%)
    );
  --primary-color-dark:
    hsl(
      calc(var(--primary-color-h) - 3),
      var(--primary-color-s),
      calc(var(--primary-color-l) - 22%)
    );

  /* // SECONDARY */
  --secondary-color: hsl(177, 100%, 71%);
  --secondary-color-h: 177;
  --secondary-color-s: 100%;
  --secondary-color-l: 71%;
  --secondary-color: hsl(var(--secondary-color-h), var(--secondary-color-s), var(--secondary-color-l));

  --secondary-color-hover:
    hsl(
      var(--secondary-color-h),
      var(--secondary-color-s),
      calc(var(--secondary-color-l) + 10%)
    );
    
  --secondary-color-light:
    hsl(
      var(--secondary-color-h),
      var(--secondary-color-s),
      calc(var(--secondary-color-l) + 20%)
    );
  --secondary-color-dark:
    hsl(
      calc(var(--secondary-color-h) - 3),
      var(--secondary-color-s),
      calc(var(--secondary-color-l) - 22%)
    );

  /* //! --- GLOBAL --- */
  --window-bg: var(--secondary-color);
  --app-bg: hsl(177, 100%, 83%);;
  --header-bg: var(--primary-color-light);

  --app-bg-inner: var(--secondary-color-light);

  /* //! --- TEXT --- */
  --text-color: rgb(0, 0, 0);
  color: var(--text-color);
  --text-link-color: rgb(0, 119, 119);
  --text-logo-color: var(--text-color);
  --text-placeholder-color: rgb(122, 122, 122);
  --spark-color: var(--btn-bg);

  /* //! --- DIVIDERS --- */
  --divider-color: black;

  /* //! --- SOCIAL ICONS --- */
  /* // This variable is not present in most themes. Only needed for ultrabight Purity theme. */
  /* --social-icon-outline:  */

  /* //! --- CARDS --- */
  --card-background: var(--primary-color);
  --card-header-bg: var(--primary-color-light);
  --card-border-color: black;
  --card-box-shadow: -2px 2px 2px black;

  /* ! --- UI ELEMENTS --- */
  /* // --- SCROLLBAR COLORS --- */
  --scrollbar-thumb: var(--primary-color-dark);
  --scrollbar-track: var(--primary-color);

  /* // --- BUTTONS --- */
  --btn-bg: var(--primary-color-dark);
  --btn-bg-hover: var(--secondary-color);

  --btn-shadow-hover-glow: 0 0 5px white;

  --btn-bg-alt: var(--secondary-color);
  --btn-bg-alt-hover: var(--secondary-color-hover);

  --btn-bg-disabled: rgb(197, 197, 197);

  --btn-text-color: black;
  --btn-text-color-hover: black;

  --btn-border: 2px solid black;
  --btn-border-hover-color: white;
  --btn-border-hover: 2px solid var(--btn-border-hover-color);

  --action-icon-color-hover: white;

  /* // --- POPUP PROMPT BUTTONS --- */
  --btn-prompt-confirm-bg: lightgreen;
  --btn-prompt-delete-bg: lightcoral;
  --btn-prompt-cancel-bg: rgb(255, 190, 190);

  /* // --- FOCUS OUTLINES */
  --focus-outline: 2px solid blue;

  /* // --- NOTE HOVER COLOR */
  --note-hover-color: hsla(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l), 50%);

  /* // --- SNOOZE ICON COLOR --- */
  --snooze-icon-color: black;
  --snooze-icon-color-hover: white;
}

/* ! ------------- END ICECORE --------------- */






/* ! ------------- START INKWELL --------------- */

:root .theme-inkwell {
  /* //! --- PRIMARY AND SECONDARY COLORS --- */
  /* // PRIMARY */
  --primary-color: hsl(0, 48%, 14%);
  --primary-color: hsl(0, 0%, 29%);
  --primary-color-h: 0;
  --primary-color-s: 0%;
  --primary-color-l: 29%;
  --primary-color: hsl(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l));

  --primary-color-light:
    hsl(
      var(--primary-color-h),
      var(--primary-color-s),
      calc(var(--primary-color-l) + 10%)
    );
  --primary-color-dark:
    hsl(
      calc(var(--primary-color-h) - 3),
      var(--primary-color-s),
      calc(var(--primary-color-l) - 22%)
    );

  /* // SECONDARY */
  --secondary-color: hsl(0, 45%, 10%);
  --secondary-color-h: 0;
  --secondary-color-s: 0%;
  --secondary-color-l: 9%;
  --secondary-color: hsl(var(--secondary-color-h), var(--secondary-color-s), var(--secondary-color-l));

  --secondary-color-hover:
    hsl(
      var(--secondary-color-h),
      var(--secondary-color-s),
      calc(var(--secondary-color-l) + 10%)
    );
    
  --secondary-color-light:
    hsl(
      var(--secondary-color-h),
      var(--secondary-color-s),
      calc(var(--secondary-color-l) + 20%)
    );
  --secondary-color-dark:
    hsl(
      calc(var(--secondary-color-h) - 3),
      var(--secondary-color-s),
      calc(var(--secondary-color-l) - 22%)
    );

  /* //! --- GLOBAL --- */
  --window-bg: var(--secondary-color);
  --app-bg: var(--secondary-color);
  --header-bg: var(--primary-color-light);

  --app-bg-inner: var(--secondary-color-light);
  --app-bg-inner: rgb(105, 105, 105);
  /* --app-bg-inner: rgb(88, 88, 88); */

  /* //! --- TEXT --- */
  --text-color: white;
  color: var(--text-color);
  --text-placeholder-color: rgb(182, 182, 182);

  --text-logo-color: var(--text-color);
  /* --text-logo-outline: black; */
  --text-outline-themed:
    0 0 1px black,
    0 0 1px black,
    0 0 1px black,
    0 0 1px black,
    0 0 2px black,
    0 0 2px black,
    0 0 2px black;

  --text-link-color: rgb(255, 255, 255);
  --spark-color: var(--text-color);

  /* //! --- DIVIDERS --- */
  --divider-color: black;

  /* //! --- SOCIAL ICONS --- */
  /* // This variable is not present in most themes. Only needed for ultrabight Purity theme. */
  /* --social-icon-outline:  */

  /* //! --- CARDS --- */
  --card-background: var(--primary-color);
  --card-background: rgb(59, 59, 59);
  --card-header-bg: var(--primary-color-light);
  --card-border-color: black;
  --card-box-shadow: -2px 2px 2px black;

  /* ! --- UI ELEMENTS --- */
  /* // --- SCROLLBAR COLORS --- */
  --scrollbar-thumb: var(--primary-color-dark);
  --scrollbar-track: var(--primary-color);

  /* // --- BUTTONS --- */
  --btn-bg: var(--primary-color-dark);
  /* --btn-bg: rgb(224, 224, 224); */
  --btn-bg-hover: var(--secondary-color-hover);
  /* --btn-bg-hover: white; */

  --btn-shadow-hover-glow: 0 0 5px white;

  --btn-bg-alt: var(--secondary-color);
  /* --btn-bg-alt: rgb(199, 199, 199); */
  --btn-bg-alt-hover: var(--secondary-color-hover);
  /* --btn-bg-alt-hover: rgb(196, 196, 196); */

  --btn-bg-disabled: var(--secondary-color-light);

  --btn-text-color: var(--text-color);
  /* --btn-text-color: black; */
  --btn-text-color-hover: var(--text-color);
  /* --btn-text-color-hover: black; */

  --btn-border: 2px solid black;
  --btn-border-hover-color: white;
  --btn-border-hover: 2px solid var(--btn-border-hover-color);

  --action-icon-color-hover: rgb(170, 170, 170);

  /* // --- POPUP PROMPT BUTTONS --- */
  --btn-prompt-confirm-bg: lightgreen;
  --btn-prompt-delete-bg: lightcoral;
  --btn-prompt-cancel-bg: rgb(255, 190, 190);

  /* // --- FOCUS OUTLINES */
  --focus-outline: 2px solid white;

  /* // --- NOTE HOVER COLOR */
  --note-hover-color: hsla(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l), 50%);

  /* // --- SNOOZE ICON COLOR --- */
  --snooze-icon-color: rgb(161, 161, 161);
  --snooze-icon-color-hover: white;
}

/* ! ------------- END INKWELL --------------- */


/* ! ------------- START PURITY --------------- */

:root .theme-purity {
  /* //! --- PRIMARY AND SECONDARY COLORS --- */
  /* // PRIMARY */
  --primary-color: hsl(0, 48%, 14%);
  --primary-color: hsl(0, 0%, 29%);
  --primary-color-h: 0;
  --primary-color-s: 0%;
  --primary-color-l: 100%;
  --primary-color: hsl(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l));

  --primary-color-light:
    hsl(
      var(--primary-color-h),
      var(--primary-color-s),
      calc(var(--primary-color-l) + 10%)
    );
  --primary-color-dark:
    hsl(
      calc(var(--primary-color-h) - 3),
      var(--primary-color-s),
      calc(var(--primary-color-l) - 22%)
    );

  /* // SECONDARY */
  --secondary-color: hsl(0, 0%, 100%);
  --secondary-color-h: 0;
  --secondary-color-s: 0%;
  --secondary-color-l: 100%;
  --secondary-color: hsl(var(--secondary-color-h), var(--secondary-color-s), var(--secondary-color-l));

  --secondary-color-hover:
    hsl(
      var(--secondary-color-h),
      var(--secondary-color-s),
      calc(var(--secondary-color-l) + 10%)
    );
    
  --secondary-color-light:
    hsl(
      var(--secondary-color-h),
      var(--secondary-color-s),
      calc(var(--secondary-color-l) + 20%)
    );
  --secondary-color-dark:
    hsl(
      calc(var(--secondary-color-h) - 3),
      var(--secondary-color-s),
      calc(var(--secondary-color-l) - 22%)
    );

  /* //! --- GLOBAL --- */
  --window-bg: var(--secondary-color);
  --app-bg: var(--secondary-color);
  --header-bg: var(--primary-color-light);

  --app-bg-inner: var(--secondary-color-light);
  --app-bg-inner: rgb(255, 255, 255);

  /* //! --- TEXT --- */
  --text-color: black;
  color: var(--text-color);
  --text-placeholder-color: rgb(182, 182, 182);
  --spark-color: var(--btn-bg);

  --text-logo-color: var(--text-color);
  /* --text-logo-outline: black; */
  /* --text-outline-themed:
    0 0 1px black,
    0 0 1px black,
    0 0 1px black,
    0 0 1px black,
    0 0 2px black,
    0 0 2px black,
    0 0 2px black; */

  --text-link-color: rgb(0, 119, 119);
  
  /* //! --- DIVIDERS --- */
  --divider-color: black;

  /* //! --- SOCIAL ICONS --- */
  /* // This variable is not present in most themes. Only needed for ultrabight Purity theme. */
  --social-icon-outline:
    0 0 1px black,
    0 0 1px black,
    0 0 1px black,
    0 0 1px black,
    0 0 2px black,
    0 0 2px black,
    0 0 2px black;

  /* //! --- CARDS --- */
  --card-background: var(--primary-color);
  /* --card-background: rgb(217, 255, 239);; */
  --card-header-bg: var(--primary-color-light);
  --card-header-bg: rgb(217, 255, 239);;
  --card-border-color: black;
  --card-box-shadow: -2px 2px 2px black;

  /* ! --- UI ELEMENTS --- */
  /* // --- SCROLLBAR COLORS --- */
  --scrollbar-thumb: var(--primary-color-dark);
  --scrollbar-track: var(--primary-color);

  /* // --- BUTTONS --- */
  --btn-bg: var(--primary-color);
  --btn-bg: rgb(217, 255, 239);;
  
  /* --btn-bg: rgb(32, 32, 32); */
  --btn-bg-hover: var(--secondary-color-hover);

  --btn-shadow-hover-glow: 0 0 5px white;

  --btn-bg-alt: var(--secondary-color);
  --btn-bg-alt: rgb(217, 255, 239);
  --btn-bg-alt-hover: var(--secondary-color-hover);

  --btn-bg-disabled: rgb(214, 214, 214);

  --btn-text-color: var(--text-color);
  --btn-text-color-hover: var(--text-color);

  --btn-border: 2px solid black;
  --btn-border-hover-color: white;
  --btn-border-hover: 2px solid var(--btn-border-hover-color);

  --action-icon-color-hover: rgb(90, 199, 152);
  /* --action-icon-color-hover: var(--btn-bg); */

  /* // --- POPUP PROMPT BUTTONS --- */
  --btn-prompt-confirm-bg: lightgreen;
  --btn-prompt-delete-bg: lightcoral;
  --btn-prompt-cancel-bg: rgb(255, 190, 190);

  /* // --- FOCUS OUTLINES */
  --focus-outline: 2px solid black;

  /* // --- NOTE HOVER COLOR */
  --note-hover-color: hsla(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l), 50%);

  /* // --- SNOOZE ICON COLOR --- */
  --snooze-icon-color: black;
  --snooze-icon-color-hover: white;

}

  

/* ! ------------- END PURITY --------------- */


/* ! ------------- ALUCARD --------------- */
:root .theme-alucard {
  /* //! --- PRIMARY AND SECONDARY COLORS --- */
  /* // PRIMARY */
  --primary-color: hsl(0, 48%, 14%);
  --primary-color: hsl(0, 0%, 29%);
  --primary-color-h: 0;
  --primary-color-s: 48%;
  --primary-color-l: 14%;
  --primary-color: hsl(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l));

  --primary-color-light:
    hsl(
      var(--primary-color-h),
      var(--primary-color-s),
      calc(var(--primary-color-l) + 10%)
    );
  --primary-color-dark:
    hsl(
      calc(var(--primary-color-h) - 3),
      var(--primary-color-s),
      calc(var(--primary-color-l) - 22%)
    );

  /* // SECONDARY */
  --secondary-color: hsl(0, 45%, 10%);
  --secondary-color-h: 0;
  --secondary-color-s: 45%;
  --secondary-color-l: 9%;
  --secondary-color: hsl(var(--secondary-color-h), var(--secondary-color-s), var(--secondary-color-l));

  --secondary-color-hover:
    hsl(
      var(--secondary-color-h),
      var(--secondary-color-s),
      calc(var(--secondary-color-l) + 10%)
    );
    
  --secondary-color-light:
    hsl(
      var(--secondary-color-h),
      var(--secondary-color-s),
      calc(var(--secondary-color-l) + 20%)
    );
  --secondary-color-dark:
    hsl(
      calc(var(--secondary-color-h) - 3),
      var(--secondary-color-s),
      calc(var(--secondary-color-l) - 22%)
    );

  /* //! --- GLOBAL --- */
  --window-bg: var(--secondary-color);
  --app-bg: var(--secondary-color);
  --header-bg: var(--primary-color-light);

  --app-bg-inner: var(--secondary-color-light);
  --app-bg-inner: rgb(105, 105, 105);
  --app-bg-inner: hsl(0, 26%, 34%);;

  /* //! --- TEXT --- */
  --text-color: white;
  color: var(--text-color);
  --text-placeholder-color: rgb(182, 182, 182);
  --spark-color: hsl(0, 100%, 65%);

  --text-logo-color: var(--text-color);
  /* --text-logo-outline: black; */
  --text-outline-themed:
    0 0 1px black,
    0 0 1px black,
    0 0 1px black,
    0 0 1px black,
    0 0 2px black,
    0 0 2px black,
    0 0 2px black;

  --text-link-color: rgb(255, 196, 196);

  /* //! --- DIVIDERS --- */
  --divider-color: var(--btn-bg-alt);

  /* //! --- SOCIAL ICONS --- */
  /* // This variable is not present in most themes. Only needed for ultrabight Purity theme. */
  /* --social-icon-outline:  */

  /* //! --- CARDS --- */
  --card-background: var(--primary-color);
  --card-background: rgb(43, 0, 0);
  --card-header-bg: var(--primary-color-light);
  --card-border-color: black;
  --card-box-shadow: -2px 2px 2px black;

  /* ! --- UI ELEMENTS --- */
  /* // --- SCROLLBAR COLORS --- */
  --scrollbar-thumb: var(--primary-color);
  --scrollbar-track: var(--primary-color-light);

  /* // --- BUTTONS --- */
  --btn-bg: var(--primary-color);
  --btn-bg: var(--secondary-color-light);
  /* --btn-bg: rgb(32, 32, 32); */
  --btn-bg-hover: var(--secondary-color-hover);
  --btn-bg-hover: hsl(0, 44%, 45%);

  --btn-shadow-hover-glow: 0 0 5px white;

  --btn-bg-alt: var(--secondary-color-light);
  /* --btn-bg-alt: rgb(32, 32, 32); */
  --btn-bg-alt-hover: var(--secondary-color-hover);
  --btn-bg-alt-hover: hsl(0, 44%, 45%);;

  --btn-bg-disabled: var(--secondary-color-light);

  --btn-text-color: var(--text-color);
  --btn-text-color-hover: var(--text-color);

  --btn-border: 2px solid black;
  --btn-border-hover-color: white;
  --btn-border-hover: 2px solid var(--btn-border-hover-color);

  --action-icon-color-hover: hsl(0, 55%, 67%);

  /* // --- POPUP PROMPT BUTTONS --- */
  --btn-prompt-confirm-bg: lightgreen;
  --btn-prompt-delete-bg: lightcoral;
  --btn-prompt-cancel-bg: rgb(255, 190, 190);

  /* // --- FOCUS OUTLINES */
  --focus-outline: 2px solid red;

  /* // --- NOTE HOVER COLOR */
  --note-hover-color: hsla(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l), 50%);

  /* // --- SNOOZE ICON COLOR --- */
  --snooze-icon-color: white;
  --snooze-icon-color-hover: white;
}

/* ! ------------- END ALUCARD --------------- */


/* ! ------------- START HYACINTH --------------- */

:root .theme-hyacinth {
  /* //! --- PRIMARY AND SECONDARY COLORS --- */
  /* // PRIMARY */
  --primary-color: hsl(270, 34%, 22%);
  --primary-color-h: 270;
  --primary-color-s: 34%;
  --primary-color-l: 22%;
  --primary-color: hsl(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l));

  --primary-color-light:
    hsl(
      var(--primary-color-h),
      var(--primary-color-s),
      calc(var(--primary-color-l) + 10%)
    );
  --primary-color-dark:
    hsl(
      calc(var(--primary-color-h) - 3),
      var(--primary-color-s),
      calc(var(--primary-color-l) - 22%)
    );

  /* // SECONDARY */
  --secondary-color: hsl(249, 36%, 22%);;
  --secondary-color: hsl(260, 48%, 22%);;
  --secondary-color-h: 260;
  --secondary-color-s: 48%;
  --secondary-color-l: 22%;
  --secondary-color: hsl(var(--secondary-color-h), var(--secondary-color-s), var(--secondary-color-l));

  --secondary-color-hover:
    hsl(
      var(--secondary-color-h),
      var(--secondary-color-s),
      calc(var(--secondary-color-l) + 10%)
    );
    
  --secondary-color-light:
    hsl(
      var(--secondary-color-h),
      var(--secondary-color-s),
      calc(var(--secondary-color-l) + 20%)
    );
  --secondary-color-dark:
    hsl(
      calc(var(--secondary-color-h) - 3),
      var(--secondary-color-s),
      calc(var(--secondary-color-l) - 22%)
    );

  /* //! --- GLOBAL --- */
  --window-bg: var(--secondary-color);
  --app-bg: var(--secondary-color);
  --app-bg: rgb(29, 0, 41);
  --header-bg: var(--primary-color-light);

  --app-bg-inner: var(--secondary-color-light);
  --app-bg-inner: rgb(82, 64, 116);

  /* //! --- TEXT --- */
  --text-color: rgb(223, 200, 255);
  color: var(--text-color);
  --text-placeholder-color: rgb(122, 122, 122);
  --text-link-color: rgb(227, 117, 255);
  --spark-color: rgb(227, 117, 255);

  --text-outline-themed:
  0 0 1px black,
  0 0 1px black,
  0 0 1px black,
  0 0 1px black,
  0 0 2px black,
  0 0 2px black,
  0 0 2px black;

  /* //! --- DIVIDERS --- */
  --divider-color: rgb(140, 105, 190);

  /* //! --- SOCIAL ICONS --- */
  /* // This variable is not present in most themes. Only needed for ultrabight Purity theme. */
  /* --social-icon-outline:  */

  /* //! --- CARDS --- */
  --card-background: var(--primary-color);
  --card-header-bg: var(--primary-color-light);
  --card-border-color: black;
  --card-box-shadow: -2px 2px 2px black;

  /* ! --- UI ELEMENTS --- */
  /* // --- SCROLLBAR COLORS --- */
  --scrollbar-thumb: var(--secondary-color);
  --scrollbar-track: var(--primary-color-light);

  /* // --- BUTTONS --- */
  --btn-bg: var(--primary-color-dark);
  --btn-bg: var(--secondary-color);
  --btn-bg-hover: var(--secondary-color-light);

  --btn-shadow-hover-glow: 0 0 5px white;

  --btn-bg-alt: var(--secondary-color);
  --btn-bg-alt-hover: var(--secondary-color-hover);

  --btn-bg-disabled: rgb(74, 63, 105);

  --btn-text-color: var(--text-color);
  --btn-text-color-hover: var(--text-color);

  --btn-border: 2px solid black;
  --btn-border-hover-color: white;
  --btn-border-hover: 2px solid var(--btn-border-hover-color);

  --action-icon-color-hover: white;

  /* // --- POPUP PROMPT BUTTONS --- */
  --btn-prompt-confirm-bg: lightgreen;
  --btn-prompt-delete-bg: lightcoral;
  --btn-prompt-cancel-bg: rgb(255, 190, 190);

  /* // --- FOCUS OUTLINES */
  --focus-outline: 2px solid rgb(255, 147, 255);

  /* // --- NOTE HOVER COLOR */
  --note-hover-color: hsla(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l), 50%);

  /* // --- SNOOZE ICON COLOR --- */
  --snooze-icon-color: var(--text-color);
  --snooze-icon-color-hover: white;
}

/* ! ------------- END HYACINTH --------------- */

/* ! ------------- RAINFOREST --------------- */
:root .theme-rainforest {
  /* //! --- PRIMARY AND SECONDARY COLORS --- */
  /* // PRIMARY */
  /* --primary-color: hsl(0, 48%, 14%); */
  --primary-color: hsl(143, 47%, 14%);
  --primary-color: hsl(160, 100%, 18%);
  /* --primary-color: hsl(28, 100%, 18%); */
  /* --primary-color: hsl(0, 0%, 29%); */
  --primary-color-h: 160;
  --primary-color-s: 100%;
  --primary-color-l: 14%;
  --primary-color-h: 143;
  --primary-color-s: 47%;
  --primary-color-l: 14%;
  --primary-color: hsl(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l));

  --primary-color-light:
    hsl(
      var(--primary-color-h),
      var(--primary-color-s),
      calc(var(--primary-color-l) + 10%)
    );
  --primary-color-dark:
    hsl(
      calc(var(--primary-color-h) - 3),
      var(--primary-color-s),
      calc(var(--primary-color-l) - 22%)
    );

  /* // SECONDARY */
  --secondary-color: hsl(143, 45%, 10%);
  /* --secondary-color: hsl(207, 63%, 15%); */
  --secondary-color-h: 143;
  --secondary-color-s: 45%;
  --secondary-color-l: 9%;
  /* --secondary-color-h: 207;
  --secondary-color-s: 63%;
  --secondary-color-l: 15%; */
  --secondary-color: hsl(var(--secondary-color-h), var(--secondary-color-s), var(--secondary-color-l));

  --secondary-color-hover:
    hsl(
      var(--secondary-color-h),
      var(--secondary-color-s),
      calc(var(--secondary-color-l) + 10%)
    );
    
  --secondary-color-light:
    hsl(
      var(--secondary-color-h),
      var(--secondary-color-s),
      calc(var(--secondary-color-l) + 20%)
    );
  --secondary-color-dark:
    hsl(
      calc(var(--secondary-color-h) - 3),
      var(--secondary-color-s),
      calc(var(--secondary-color-l) - 22%)
    );

  /* //! --- GLOBAL --- */
  --window-bg: var(--secondary-color);
  --app-bg: var(--secondary-color);
  --app-bg: hsl(141, 100%, 3%);;
  /* --app-bg: hsl(28, 100%, 3%);; */
  --header-bg: var(--primary-color-light);

  --app-bg-inner: var(--secondary-color-light);
  /* --app-bg-inner: rgb(105, 105, 105); */
  --app-bg-inner: hsl(142, 21%, 40%);

  /* //! --- TEXT --- */
  --text-color: rgb(255, 255, 255);
  color: var(--text-color);
  --text-placeholder-color: rgb(182, 182, 182);


  --text-logo-color: var(--text-color);
  /* --text-logo-outline: black; */
  --text-outline-themed:
    0 0 1px black,
    0 0 1px black,
    0 0 1px black,
    0 0 1px black,
    0 0 2px black,
    0 0 2px black,
    0 0 2px black;

  --text-link-color: rgb(52, 243, 170);
  --spark-color: rgb(52, 243, 170);

  /* //! --- DIVIDERS --- */
  --divider-color: var(--btn-bg-alt);

  /* //! --- SOCIAL ICONS --- */
  /* // This variable is not present in most themes. Only needed for ultrabight Purity theme. */
  /* --social-icon-outline: 0 0 2px white; */

  /* //! --- CARDS --- */
  --card-background: var(--primary-color);
  /* --card-background: rgb(43, 0, 0); */
  --card-header-bg: var(--primary-color-light);
  --card-border-color: black;
  --card-box-shadow: -2px 2px 2px black;

  /* ! --- UI ELEMENTS --- */
  /* // --- SCROLLBAR COLORS --- */
  --scrollbar-thumb: var(--primary-color);
  --scrollbar-track: var(--primary-color-light);

  /* // --- BUTTONS --- */
  --btn-bg: var(--primary-color);
  --btn-bg: var(--secondary-color-light);
  /* --btn-bg: rgb(63, 39, 19); */
  --btn-bg-hover: var(--secondary-color-hover);
  /* --btn-bg-hover: var(--secondary-color-light); */
  --btn-bg-hover: hsl(143, 44%, 45%);

  --btn-shadow-hover-glow: 0 0 5px white;

  --btn-bg-alt: var(--secondary-color-light);
  /* --btn-bg-alt: rgb(63, 39, 19); */
  /* --btn-bg-alt: hsl(142, 61%, 20%); */
  --btn-bg-alt-hover: var(--secondary-color-hover);
  --btn-bg-alt-hover: hsl(143, 44%, 45%);;

  --btn-bg-disabled: var(--secondary-color-light);

  --btn-text-color: var(--text-color);
  --btn-text-color-hover: var(--text-color);

  --btn-border: 2px solid black;
  --btn-border-hover-color: white;
  --btn-border-hover: 2px solid var(--btn-border-hover-color);

  --action-icon-color-hover: hsl(143, 52%, 54%);

  /* // --- POPUP PROMPT BUTTONS --- */
  --btn-prompt-confirm-bg: lightgreen;
  --btn-prompt-delete-bg: lightcoral;
  --btn-prompt-cancel-bg: rgb(255, 190, 190);

  /* // --- FOCUS OUTLINES */
  --focus-outline: 2px solid lime;

  /* // --- NOTE HOVER COLOR */
  --note-hover-color: hsla(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l), 50%);
  
  /* // --- SNOOZE ICON COLOR --- */
  --snooze-icon-color: white;
  --snooze-icon-color-hover: white;
}

/* ! ------------- END RAINFOREST --------------- */