.hover-tooltip-wrapper {
  position: relative;
  overflow: visible;
}


.hover-tooltip-box {
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  margin: auto; 

  display: flex;
  justify-content: center;

  pointer-events: none;
}

.hover-tooltip {
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  z-index: 5;
  border: 1px dotted var(--text-color);
  border-radius: 5px;
  padding: .5rem;
  background: var(--app-bg-inner);

  color: var(--text-color);
  transition: .2s linear .1s;
  white-space: nowrap;
}

.hover-tooltip-left {
  left: 0px;
  right: unset;
  transform: unset;
}

.hover-tooltip-right {
  left: unset;
  right: 0px;
  transform: unset;
}

.hover-tooltip-rightlow {
  top: 25px;
  right: 50px;
  transform: unset;
}

.hover-tooltip-wrapper:hover .hover-tooltip {
  opacity: 1;
}

.hover-tooltip-text {
  font-size: 1.5rem;
  font-weight: bold;
}