.account-limit-counter-wrapper {
  position: relative;
}


.account-limit-counter {
  background: var(--app-bg);
  font-size: 1.5rem;
  padding: .4rem;
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 5px;
  z-index: 5;
  user-select: none;
}

.contact-notes-wrapper .account-limit-counter {
  right: 39px;
}

.header .account-limit-counter {
  right: 10px;
  top: 13px;
}