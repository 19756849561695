.section-wrapper {
  border: 4px solid black;
  border-radius: 20px;
  margin-top: 1rem;
  background-color: var(--card-background);
  box-shadow: var(--card-box-shadow);
}


.section-header {
  display: block;
  font-size: 3rem;
  padding: 1rem 2rem;
  background: rgb(255, 217, 147);
  border-radius: 20px 20px 0 0;
  border-bottom: 2px solid black;
  text-align: center;
}


.section-content-wrapper {
  display: flex;
  flex-flow: column nowrap;
}

.section-content {
  flex: 1;
  padding: 1rem;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  justify-content: space-around;
  justify-content: center;
  line-height: 2.2rem;
}

.section-content.section-left {
  flex: 1;
}

.section-text {
  margin-bottom: 2rem;
}

.section-attribution {
  margin-top: 2rem;
  text-align: left;
  font-size: 1.2rem;
}

.section-left {
  display: flex;
  align-items: center;
  justify-content: center;
}


.section-content-img {
  width: 100%;
  max-width: 400px;

  border-radius: 5px;
  border: 2px solid black;
  box-shadow: -3px 3px 5px black;
  pointer-events: none;
}

.section-wrapper:first-child .section-content-img,
.section-wrapper:last-child .section-content-img {
  border: none;
  box-shadow: none;
  pointer-events: none;
}


.section-content-img-border {
  border-radius: 10px;
  border: 2px solid black;
}

@media all and (min-width: 680px) {
  .section-content-wrapper {
    flex-flow: row nowrap;
  }
  .mirrored .section-content:first-child {
    order: 2
  }
  .locked {
    padding-right: 0;
  }
}

@media all and (min-width: 680px) {
  .locked .section-content-img {
    width: 400px;
  }
}