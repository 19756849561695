.welcome-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 330px;
}

.welcome-text p {
  font-size: 3rem;
  font-size: 2.1rem;
}

.welcome-btns {
  width: 100%;
  margin-top: auto;
}