.popup-wrapper-bg {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,.5);
}

.popup-wrapper .card-wrapper {
  position: absolute;
  z-index: 15;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 300px;
  max-width: calc(100vw - 20px);
  /* max-height: 95vh; */
}

.popup-header {
  background: var(--card-header-bg);
  border-radius: 10px 10px 0 0;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito';

  padding: 1rem;
}

.popup-header h1 {
  font-weight: bold;
  white-space: nowrap;
  font-size: 2.5rem;
}

.checkin-popup-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2.5rem;
  text-transform: capitalize;
}

.popup-body {
  padding: 1rem;
}

.checkin-popup-checkin-action {
  border-radius: 5px;
  border: 1px dotted black;
  padding: .3rem;
}

.popup-body-text {
  text-align: center;
  margin-bottom: 2rem;
}
.popup-body-text-warning {
  color: red;
  font-weight: bold;
  font-size: 2.5rem;
}


/* ! For TextArea, only in Add/Edit Note Popup */
#noteText {
  height: 6.5em;
  resize: none;
}

/* ! End Textarea */

.prompt-btns {
  display: flex;
  flex-flow: row nowrap;

}

.prompt-btns .material-icons-round,
.prompt-btns .material-icons {
  font-size: 3rem;
  color: black;
}

.prompt-btn-event-wrapper:last-child {
  margin-right: 0;
}

.prompt-btn-event-wrapper {
  flex: 1;
  margin-right: 1rem;
}

.prompt-btn {
  padding: .5rem;
  height: 4.5rem;
}



/* ! --- FOR HELP POPUPS --- */
.help-wrapper .card-wrapper{
  /* min-height: 490px; */
  display: flex;
  flex-flow: column nowrap;
}

.popup-body {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
}

.help-wrapper .prompt-btns {
  margin-top: auto;
}




/* ! For Error Message popups */
.error-message-bg {
  z-index: 30;
}

.error-message-wrapper .card-wrapper {
  z-index: 35 ;
}

.error-message {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.error-message:last-of-type {
  margin-bottom: 2rem;
}


/* ! For message popups */
.popup-message{
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}