.hud-wrapper {
  padding: .5rem;
  text-align: center;
}

.hud-category-select-section-wrapper {
  display: flex;
  flex-flow: row nowrap;

  margin-bottom: .5rem;
}

.hud-category-icon {
  background: var(--btn-bg-alt);

  min-width: 3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid black;
  border-bottom: 1px solid black;

  transition: .3s;
}

.hud-category-btn {
  cursor: pointer;
  box-shadow: inset -2px -2px 2px rgb(0,0,0,.5), inset 2px 2px 2px rgb(255,255,255,.5);

  transition: .3s;
}

.hud-category-btn:hover {
  transition: .3s;
  background: var(--btn-bg-alt-hover);
}
.hud-category-btn:active {
  transition: 0s;
  background:var(--btn-bg-alt-hover);
  box-shadow: none;
}

.hud-category-btn .material-icons-round {
  font-size: 2.8rem;
}

.hud-category-btn-left {
  border-radius: 5px 0 0 5px;
  border-left: 1px solid black;
}
.hud-category-btn-right {
  border-radius: 0 5px 5px 0;
  border-right: 1px solid black;
}

.hud-category-btn.btn-disabled {
  background: var(--btn-bg-disabled);
  color: black;
  box-shadow: none;
  cursor: not-allowed;
}

.hud-category-select-wrapper {
  width: 100%;
}

.hud-category-select {
  cursor: pointer;
  width: 100%;
  height: 3.5rem;
  color: var(--text-color);
  font-weight: bold;
  font-size: 2rem;
  font-family: 'Nunito';

  background: var(--app-bg-inner);

  text-align: center;
  text-align-last: center;
  text-transform: capitalize;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 0px;
  border-left: 0px;
  /* // This padding "centers" the selected category, offsetting the size of the dropdown arrow */
  padding-left: 1.4rem;

  box-shadow: var(--btn-shadow-inner-small);
  transition: .3s;
}

.hud-category-select:hover,
.hud-category-select:focus {
  box-shadow: var(--btn-shadow-inner-hover);
  background: var(--btn-bg-alt-hover);
}
.hud-category-select:hover .hud-category-option,
.hud-category-select:active .hud-category-option {
  background: var(--app-bg-inner);
}
.hud-category-select:active {
  box-shadow: none;
  transition: 0s;
}


.hud-wrapper option {
  color: var(--text-color);
  cursor: pointer;
  text-transform: capitalize;
}

.hud-divider {
  height: 2px;
  width: 80%;
  margin: 1rem auto;
  border: 1px solid var(--divider-color);
}

/* //! --- SORT BY AND SEARCH --- */
.hud-user-info {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: .5rem;
}

.hud-user-info .contact-detail-value {
  align-items: center;
}

.hud-user-info .contact-detail-value-li {
  width: 100%;
  word-wrap: break-word;
}

.sort-search-wrapper {
  display: flex;
  flex-flow: row nowrap;
}


.search-wrapper,
.sort-by-wrapper {
  flex-basis: 50%;
}

.sort-by-select,
.search-input {
  width: 100%;
  font-size: 1.25rem;
}

.sort-by-select {
  padding-left: .1rem;
}

@media all and (min-width: 380px) {
  .sort-by-select,
  .search-input {
    font-size: 1.5rem;
  }
}

@media all and (min-width: 450px) {
  .sort-by-select,
  .search-input {
    font-size: 2rem;
  }
  .sort-by-select {
    padding-left: 1.4rem;
  }
}

.search-wrapper {
  position: relative;
}



.search-input {
  box-shadow: none !important;
  cursor: unset;
  text-transform: none;
  padding-left: 0;
}


.search-input::placeholder {
  color: var(--text-placeholder-color);
}

.sort-search-btn-left {
  border-left: 1px solid black;
  border-radius: 5px 0 0 5px;
}

.sort-search-btn-right {
  border-right: 1px solid black;
  border-left: 1px solid rgba(0,0,0,0);
  border-radius: 0 5px 5px 0;
}
.sort-search-btn-right:hover {
  border-left: 1px solid black;
}

.sort-search-btn-right label {
  cursor: pointer;
}

.search-clear-btn {
  border-left: 1px solid rgba(0,0,0,0)
}

.search-clear-btn:hover {
  border-left: 1px solid black;
}