.contact-wrapper {
  display: flex;
  flex-flow: column nowrap;

  transition: var(--footer-animation-duration);
}

/* ! --- CONTACT HEADER --- */
.contact-header {
  background: var(--card-header-bg);
  border-radius: 10px 10px 0 0;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 1rem 0.5rem;

  position: relative;
}

.contact-header-name {
  font-family: 'Nunito';
  font-weight: bold;
  text-transform: capitalize;
  font-size: 3rem;
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  word-break: break-word;
}

/* //// Contact Action Buttons */
.contact-actions-wrapper {
  user-select: none;
  display: flex;
  flex-flow: row nowrap;
}

.action-icon {
  transition: 0.7s;
  font-size: 2.4rem;
}
.action-icon:hover {
  cursor: pointer;
  color: var(--action-icon-color-hover);

  transition: 0.3s;
}

.delete-icon:hover {
  color: var(--btn-prompt-delete-bg);
}

.contact-favicon-wrapper {
  align-self: flex-start;
  width: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.contact-favicon {
  font-size: 2.8rem;

  /* margin-top: 1px; */
}

.spin-pulse {
  animation: spin-pulse 2s ease 0s forwards;
  transition: 0;
}

@keyframes spin-pulse {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(360deg);
  }
  /* 90% {
    transform: rotate(360deg) scale(1.1);
  } */
  100% {
    /* transform: rotate(360deg) scale(1); */
    transform: rotate(360deg);
  }
}

/* //// Contact Category */
.contact-category-wrapper {
  position: absolute;
  text-transform: capitalize;
  left: 10px;
  left: 0px;
  top: -14px;
  font-size: 1.7rem;
  background: var(--app-bg-inner);
  background: var(--card-header-bg);
  border-radius: 5px;
  border-radius: 5px 5px 0 0;
  padding: 0.3rem 0.4rem 0.4rem 0.4rem;
  z-index: 1;
}

.contact-category::after {
  content: '';
  position: absolute;
  top: 0;
  left: -2px;
  width: 100%;
  height: 11px;
  border-top: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid black;
  border-radius: 5px 5px 0 0;

  pointer-events: none;
}

.card-wrapper-tabbed {
  border-top-left-radius: 0;
  /* margin-bottom: 3rem !important; */
}

/* ! --- CONTACT BODY --- */
.contact-body {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0.5rem;
}

/* //! DEFAULT: Mobile 320px - 499px */
/* // CONTACT IMAGE */
.contact-image-section {
  order: 1;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  /* cursor: pointer; */
}

.contact-image-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.portrait-wrapper {
  border: 2px solid black;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--secondary-color-light);
  z-index: 1;
  transition: 0.3s;
}

.hover-glow:hover .portrait-wrapper {
  border: 2px solid var(--btn-bg-alt);
}

.portrait {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.portrait-placeholder {
  font-size: 20rem;
  font-size: 10rem;
}

.toggle-visibility-btn-wrapper {
  cursor: pointer;
  /* padding: 0.5rem 0.5rem 0.2rem 0.2rem; */
  /* padding: 0.5rem; */
  padding: 0.3rem;
  width: 3.3rem;
  display: flex;
  justify-content: center;
  background-color: var(--btn-bg-alt);
  position: absolute;
  left: 0;
  bottom: 0;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: var(--btn-shadow-inner-small);
  transition: 0.3s;
}
.contact-image-section:active .toggle-visibility-btn-wrapper {
  box-shadow: none;
  transition: 0s;
}
.contact-image-section:hover .toggle-visibility-btn-wrapper {
  background: var(--btn-bg-alt-hover);
}

/* // CONTACT DETAILS SECTIONS */
.checkin-details-section {
  order: 2;
  margin-left: 0.5rem;
}
.company-met-combo-section {
  order: 3;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}
.company-met-combo-section > * {
  flex: 1;
}

.company-details-section {
  width: 100%;
  margin-bottom: 0.5rem;
}

.contact-met-section {
  width: 100%;
}

.next-checkin-message-section {
  flex: 1;
  order: 5;
  flex-basis: 100%;
}

.bio-notes-section {
  order: 6;
  width: 100%;
  white-space: pre-line;
}

.contact-info-icons {
  order: 7;
}

.checkin-actions-section {
  width: 100%;
  order: 8;
  margin-top: 0.5rem;
}

/* //! TABLET/MONITOR: >=500px */
@media all and (min-width: 500px) {
  .company-met-combo-section {
    order: 1;
    width: unset;
    min-width: unset;
    flex: 1;
  }

  .contact-met-section,
  .contact-location-section {
    display: flex;
  }

  .contact-met-section .contact-detail,
  .contact-location-section .contact-detail {
    flex: 1;
  }
}

/* ! --- CONTACT INFORMATION SECTION */
.contact-info-section {
  flex: 4;
}

.contact-info-row {
  display: flex;
  flex-basis: 100%;
}

/* //! Checkin Icons */
/* // ICONS: */
.hourglass-soon {
  color: orange;
  text-shadow: var(--text-outline-dark);
}
.hourglass-due {
  color: red;
  text-shadow: var(--text-outline-dark);
}
/* .hourglass-later {
  color: green;
  text-shadow: 0 0 1px black, 0 0 2px black
} */
/* // TEXT: */
.text-soon {
  color: orange;
  font-weight: bold;
  text-shadow: var(--text-outline-dark);
}
.text-due {
  color: rgb(255, 84, 84);
  font-weight: bold;
  text-shadow: var(--text-outline-dark);
}

/* //! --- BIO/NOTES --- */
.contact-bio-wrapper,
.contact-notes-wrapper {
  max-height: 200px;
  position: relative;
}

.contact-bio,
.contact-notes {
  display: inline-block;
  overflow: auto;
  max-height: 500px;
  width: 100%;
  padding: 0.5rem;
  border-top: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-radius: 0 5px 5px 0;
  background: var(--app-bg-inner);
}

.no-bio-message {
  display: flex;
  justify-content: center;
  text-align: center;
}

.contact-notes {
  display: flex;
  flex-flow: column nowrap;
  max-height: 500px;
  overflow: auto;
  border-radius: 0 0 0 0;
  border-right: 0;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.note-year-wrapper {
  width: 100%;
}

.note-year-header {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.note-date-header {
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
}

.note-year-header,
.note-date-header {
  letter-spacing: 0.1rem;
}

.contact-note {
  position: relative;
  width: 100%;
  text-indent: 0.9rem;
  padding: 0.4rem;
}

.contact-note:last-of-type {
  margin-bottom: 1rem;
}

.contact-note:before {
  content: '';

  position: absolute;
  left: 3px;
  top: 12px;

  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: var(--text-color);
}

.contact-note:hover {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(255, 176, 87, 0.3);
  background: var(--note-hover-color);
}

/* // ADD NOTE BUTTON/UI */
.notes-btn {
  background: var(--app-bg);
  background: var(--btn-bg-alt);
  min-width: 3rem;

  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  cursor: pointer;
  box-shadow: var(--btn-shadow-inner-small);
  border-top: 1px solid black;
  border-bottom: 1px solid black;

  transition: 0.5s;
}

.notes-btn .material-icons-round {
  font-size: 2.8rem;
}

.notes-btn-left {
  border-radius: 5px 0 0 5px;
  border-right: 1px solid rgba(0, 0, 0, 0);
  border-left: 1px solid black;
}

.notes-btn-right {
  border-radius: 0 5px 5px 0;
  border-right: 1px solid black;
  border-left: 1px solid rgba(0, 0, 0, 0);
}

.notes-btn:hover {
  border: 1px solid black;
  transition: 0.3s;
  background: var(--btn-bg-alt-hover);
}

.notes-btn:active {
  box-shadow: none;

  transition: 0s;
}

/* // --- SOCIAL ICONS --- */
.contact-info-icons {
  margin-top: 0.5rem;
}

/* ! --- EDIT CONTACT FORM ANIMATIONS */
.edit-form-wrapper {
  opacity: 1;
  max-height: 100rem;

  overflow: hidden;

  transition: var(--footer-animation-duration);
}
.shrink {
  max-height: 0;
  transition: var(--footer-animation-duration);
}

.edit-fadeout {
  opacity: 0;
  transition: var(--footer-animation-duration);
}
